import { Version } from './version.model';

export const version: Version = {
  build: {
    time: '2024-10-11T09:09:02+0000',
  },
  git: {
    branchName: 'task/new-change-tenant',
    commitHash: 'bcc103c2c84bfb4cd854a50064d16eeecef4e63d',
  },
};
